/* CSS for digitalhealth.no */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte
  #main.vrtx-article-listing
  .vrtx-default-article
  .vrtx-title
  a.vrtx-title,
.ui-accordion.ui-widget .ui-accordion-header,
h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
  color: @font-primary;
}

h1 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 5rem;
  line-height: 50px;
  text-align: center;
  margin-bottom: 40px;

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    margin-top: 20px;
    background: @color-line;
  }

  &#hdr {
    padding-right: 0;
  }
}

#comments-header-left a,
#main :not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.add-comment-header,
h2 {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 3.8rem;
  line-height: 38px;
  font-weight: bold;
}

h2.menu-label {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  margin-bottom: 20px;
}

a {
  color: @color-link;
}

body {
  font-family: @font-sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.77;
  color: @color-dark;
}

.not-for-ansatte .introduction-div,
.not-for-ansatte .vrtx-frontpage-box.introduction .vrtx-box-content,
.not-for-ansatte .vrtx-introduction,
.not-for-ansatte .vrtx-introduction-big,
.not-for-ansatte .vrtx-organizational-unit-introduction {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.67;
  font-weight: 600;
  font-family: @font-sans-serif;
}

.ui-accordion.ui-widget .ui-accordion-content {
  color: @color-dark;
}

.vrtx-paging-feed-wrapper {
  display: none;
}

#button a,
.button a,
.not-for-ansatte #main .button,
.not-for-ansatte #main button {
  background-color: @color-secondary !important;
  color: @color-light;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 600;

  &:focus,
  &:hover {
    background-color: #005684 !important;
  }
}

#button a,
.not-for-ansatte
  #main
  .button:not(.study-select):not(.edit-link):not(.program-create-print):not(.program-create-send-dialog):not(.white),
.not-for-ansatte:not(#vrtx-searchview) #main button {
  border-radius: 30px;
  padding: 14px 30px;
  background-image: none;
}

#vrtx-frontpage
  #main
  #total-main
  #vrtx-content
  #vrtx-main-content
  .full-width-picture-bg:not(.pub-bg) {
  background-color: #3aaae2 !important;
  background-color: @color-primary !important;

  .vrtx-box-content {
    background: transparent;
    top: 0;
    width: 100%;
    text-align: center;
  }
}
/* search */
.not-for-ansatte.header-search-collapsable .header-search-expand {
  background: url("../images/search-black.svg") no-repeat 10px center
    transparent;
  background-size: 15px;
  color: @color-dark;
}

.not-for-ansatte.header-search-collapsable .header-search-expand:focus,
.not-for-ansatte.header-search-collapsable .header-search-expand:hover {
  text-decoration: none;
  background-color: @color-secondary--light;
}

.not-for-ansatte {
  #head-wrapper {
    background: @color-neutral--light;

    .uio-app-name {
      display: none;
    }

    #head {
      height: 128px;

      .head-menu {
        > ul > li a,
        > .vrtx-login-manage-component > a {
          color: @color-neutral--dark;
        }

        .vrtx-login-manage-component {
          .vrtx-dropdown-link {
            background: transparent
              url("../uio1/images/head-dropdown-toggle-black.png") no-repeat
              scroll 100% 53%;
          }
        }

        .vrtx-login-manage-component a {
          color: @color-neutral--dark;
        }
      }

      #header {
        width: 100%;
        position: absolute;

        .logo {
          top: 0;
          position: absolute;

          a {
            display: block;
            padding: 25px 0 0 5px;
            img {
              width: 320px;
            }
          }
        }
      }

      .header-search input[type="text"] {
        background: @color-text-field;
        color: @color-neutral--dark;
      }
    }
  }

  #globalnav {
    background: @color-neutral--light none repeat scroll 0 0;
    padding-bottom: 15px;

    ul li {
      margin: 0 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &.vrtx-active-item a,
      a {
        color: @color-dark;
        padding: 5px 5px 3px;

        &:focus,
        &:hover {
          background: @color-neutral--light none repeat scroll 0 0;
          color: @color-dark;
          margin-top: 0;
          border-bottom: 5px solid @color-secondary;
        }

        &.brown {
          background: @color-brown;
        }
      }

      &.vrtx-active-item a {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-neutral--dark;
        border-bottom: 5px solid @color-secondary;

        &.brown {
          background: @color-brown;
        }
      }
    }
  }
}
/* general elements */
.not-for-ansatte #main {
  min-height: 300px;
}

#main.narrow {
  #total-main,
  #total-main #vrtx-content,
  #total-main #vrtx-main-content:last-child {
    width: 800px;
  }

  #total-main #vrtx-additional-content,
  #total-main #vrtx-main-content,
  #vrtx-main-user {
    width: 100%;
  }

  #total-main #vrtx-additional-content {
    padding: 15px;
    background: @color-brown;
  }
}

#footer-wrapper,
.gen-person-component,
.logo,
.person,
.top-info.person-listing,
.vrtx-back,
.vrtx-box-content {
  a:focus,
  input:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.projects a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

#comments-header-left a,
#main :not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.add-comment-header {
  color: @color-link;
}

.uio-info-message.alert {
  background: @color-primary;
}

.ui-accordion.ui-widget .ui-accordion-header {
  border-bottom: none;

  &:first-child {
    border-top: none;
  }

  .ui-icon {
    display: inline-block;
    background: url("../images/icon-chevron-right-blue.svg") center left
      no-repeat;
    background-size: auto 100%;
    width: 30px;
    height: 1em;
    margin-bottom: -0.1em;
    margin-left: -30px;
    position: static;
  }

  &.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

#main #vrtx-daily-events .vrtx-daily-events-listing,
#main #vrtx-daily-events .vrtx-resource,
#main .vrtx-resources .vrtx-daily-events-listing,
#main .vrtx-resources .vrtx-resource {
  border-bottom: 1px solid @color-line;
  padding-bottom: 40px;
  margin-bottom: 40px;
  width: 100%;
}

.vrtx-authors,
.vrtx-date-info,
.vrtx-tags {
  border-top: none;
}

#main #left-main {
  .vrtx-breadcrumb-menu {
    li.vrtx-child > a,
    li.vrtx-child > span {
      border-top: 1px solid @color-line;
      padding-left: 15px;
      position: relative;
    }

    li > a.vrtx-marked,
    li > span.vrtx-marked,
    li.vrtx-child > a.vrtx-marked {
      background: transparent;
      color: @color-dark;
      font-weight: 600;
      border-top: 1px solid @color-line;
    }

    li.vrtx-child > a.vrtx-after-marked {
      border-color: @color-neutral--light;
    }

    li > a,
    li > span {
      background: transparent;

      &::before {
        content: "";
      }
    }

    li:not(.vrtx-parent):last-of-type a,
    ul li:last-of-type a {
      border-bottom: 1px solid @color-line;
      border-top: 1px solid @color-line;
    }

    li.vrtx-ancestor > a:after,
    li.vrtx-ancestor > span:after {
      display: none;
      content: " ";
    }
  }
}
/* Center content */
.vrtx-frontpage-box.center-content {
  text-align: center;
}
/**** PERSON LISTING ****/
#total-main {
  div.empty.person-listing {
    height: 251px;
  }

  div.person-listing {
    h2,
    p {
      width: 970px;
      max-width: 970px;
      margin: 0 auto;
      text-align: center;
    }

    h2 {
      font-size: 28px;
      font-size: 2.8rem;
      line-height: 38px;
      line-height: 3.8rem;
      font-weight: bold;
      padding-bottom: 10px;
    }
  }

  div.top-info.person-listing {
    text-align: center;
    background: @color-primary;
    position: absolute;
    width: 100%;
    left: 0;
    top: 184px;
    min-height: 320px;

    a {
      display: block;
      margin-top: 20px;
    }

    a,
    p {
      font-family: @font-sans-serif;
      color: black;
    }

    p:first-of-type {
      padding: 80px 0 20px;
      font-size: 40px;
      font-size: 4rem;
      line-height: 4.8rem;
      line-height: 48px;
      font-weight: bold;
    }

    a {
      color: @color-dark;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  #vrtx-listing-filter-results {
    margin-top: 40px;
    display: block;
  }

  #vrtx-listing-filter-results div.person,
  div.gen-person-component {
    list-style: none;
    padding: 20px 0 0;
    overflow: hidden;

    img {
      width: 130px;
      height: 130px !important;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;
      max-width: none;
      max-height: none;
    }

    a {
      display: block;
      font-size: 22px;
      font-size: 2.2rem;
      font-weight: bold;
    }
  }
}
/* Person page */
#main .vrtx-back {
  margin: 0 0 20px;

  a:before {
    background: url("../images/icon-chevron-back-blue.svg") 0 0.2ex no-repeat;
  }
}

.person-top-bg {
  width: 100%;
  height: 450px;
  position: absolute;
  background: @color-brown;
  z-index: -1;
}

.vrtx-frontpage-full-width.total-main
  #main.gen-person-component-listing
  #vrtx-main-content {
  h2 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 34px;
    line-height: 3.4rem;
    font-weight: 400;
  }

  .dig-person-listing .vrtx-box-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    .gen-person-component > a:first-of-type {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;

      img {
        width: auto;
        height: auto !important;
        object-fit: cover;
        text-align: center;
        max-width: 110%;
        border-radius: 0;
      }
    }
  }
}

#main.vrtx-person {
  .header h1#hdr {
    margin-bottom: 15px;

    &::after {
      display: none;
    }
  }

  #vrtx-listing-filter-hits {
    text-align: center;
    font-size: 16px;
  }

  .vrtx-person-image {
    position: relative;
    margin: 0 auto;
    width: 200px;

    .person-image {
      border-radius: 50%;
      width: 200px;
      height: 200px !important;
      margin: 0 auto;
      display: block;
      margin-bottom: 20px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    a#vrtx-press-photo {
      display: none;
      width: 150px;
      text-align: center;
      padding: 14px 30px;
      position: absolute;
      bottom: 72px;
      left: 0;
      background: @color-secondary;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      color: white;
      border-radius: 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    &:focus a#vrtx-press-photo,
    &:hover a#vrtx-press-photo {
      display: inline-block;
    }
  }

  span.position {
    width: 100%;
    display: block;
    max-width: none;
    text-align: center;
    margin-bottom: 40px;
  }

  .header {
    margin-bottom: 60px;
  }

  #total-main {
    h2:nth-of-type(1) {
      padding-top: 60px;
    }

    .content h2:first-of-type {
      padding-top: 0;
    }
  }

  #vrtx-person-contact-info-wrapper {
    .vrtx-person-contactinfo {
      margin-left: 0;
    }

    .vrtx-person-image,
    .vrtx-person-image {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      margin: 0 auto;
      display: block;
      margin-bottom: 20px;
    }
  }

  #vrtx-person-contact-info-extras {
    border-top: none;
  }
}
/* publika: on publikasjoner-page & ansatte page */
.publika-headeryear {
  padding: 20px 0 0;

  b {
    font-size: 20px;
    font-size: 2rem;
    line-height: 29px;
    line-height: 2.9rem;
  }
}

p.publika-paper {
  br {
    display: none;
  }

  span.publika-authors::after,
  span.publika-pages::after,
  span.publika-title::after {
    content: ".";
  }
}
/**Publikasjoner page */
/*top banner */
.top-banner.pub {
  margin-top: -40px;
  height: 320px;
  max-height: 320px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;

  img {
    width: 100% !important;
    display: none;
  }
}
/* publication page new design 2021 (banner + publika feed) */
.grid-container.full-width-picture-bg.pub-bg {
  height: 320px;
}

.full-width-picture.load-pretty {
  opacity: 0;
  /*display: none;*/
}
/*If styling for publication page only: .pub-bg-color.vrtx-frontpage-box div.publika p.publika-paper {*/
div.publika p.publika-paper {
  background: @color-brown;
  border-radius: 27px;
  padding: 30px 35px;
  margin: 25px 0 12.5px;

  a {
    color: #005684;
    text-decoration: underline;
  }
}
/* News feed page */
#main.vrtx-article-listing:not(.vrtx-project) .vrtx-resources {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
}

#main.vrtx-article-listing:not(.vrtx-project) .vrtx-resources .vrtx-resource {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;

  a.vrtx-image {
    margin: 0;
    width: 100%;
    max-height: 360px;
    overflow: hidden;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      max-width: none;
      width: 100%;
    }

    & ~ * {
      margin-left: 0;
    }
  }

  .vrtx-title {
    min-height: 138px;
    width: 100%;
    display: block;
    margin-bottom: 0;
  }

  a.vrtx-title {
    font-weight: bold;
    display: block;
    width: 100%;
  }

  .description,
  .published-date,
  .vrtx-read-more {
    display: none;
  }
}
/* Article page (news article )*/
#main
  #total-main
  #vrtx-content
  #vrtx-main-content
  .vrtx-introduction-image.small-intro-image {
  display: none;
}
/* Projects */
/* Project listing */
/* Hide filter results on archive page */
#right-main #vrtx-listing-filter-hits {
  display: none;
}

.projects .project {
  width: 100%;
  position: relative;
  padding-bottom: 0;
  margin-bottom: 0;
  padding: 15px 0;
  border-bottom: 1px solid @color-line;

  &:last-of-type {
    border-bottom: none;
  }

  a {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;

    &.subtitle {
      position: relative;
      font-weight: normal;
      font-size: 18px;
      font-size: 1.8rem;
      color: @color-dark;

      &:focus,
      &:hover {
        text-decoration: none;
      }

      span {
        display: inline-block;

        &:first-of-type {
          float: none;
          padding-right: 40px;
        }

        &:last-of-type {
          float: right;
        }
      }

      .after {
        width: 34px;
        height: 34px;
        background: url("../images/icon-chevron-right-blue.svg") no-repeat
          center right;
        background-size: 30px;
        padding-bottom: 16.75px;
        position: absolute;
        right: 0;
        bottom: 16.75px;
      }
    }
  }

  a.vrtx-image {
    display: none;
  }

  div.vrtx-title {
    margin-left: 0;
    margin-bottom: 0;
  }

  .vrtx-read-more {
    margin-top: 0;
    position: absolute;
    height: 40px;
    right: 0;
    top: 50%;
    /* position the top  edge of the element at the middle of the parent */
    transform: translateY(-50%);

    a {
      width: 40px;
      height: 40px;
      display: inline-block;
    }

    a::before {
      content: "";
      width: 100%;
      height: 100%;
      background: url("../images/icon-chevron-right-blue.svg") no-repeat center
        center;
      background-size: 30px;
      float: right;
    }
  }
}
/* Project page */
.top-banner.project {
  margin-top: 0;
  height: 320px;
  margin-bottom: 0;
  width: 100% !important;
  background-color: @color-primary;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  img {
    display: none;
  }

  .vrtx-introduction-image {
    width: 100% !important;
    background-color: @color-primary;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    img {
      display: none;
    }
  }
}

#main.project .participants {
  h2 {
    padding-bottom: 40px;
  }

  .participant {
    width: 50%;
    float: left;
    padding-bottom: 40px;

    a {
      display: block;
      font-size: 22px;
      font-size: 2.2rem;
      font-weight: bold;
    }

    img {
      width: 130px;
      height: 130px !important;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

#main.vrtx-project .vrtx-back {
  display: inline-block;
  padding-right: 20px;

  &:nth-of-type(3) a::before {
    display: inline-block;
  }

  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6) {
    display: none;
  }
}

/* Project Frontpage */
#main.vrtx-project {
  padding-bottom: 40px;

  #left-main {
    display: none;
  }

  #right-main {
    width: 800px;
    float: none;
    margin: 0 auto;
    p {
      max-width: none;
    }
    .content {
      padding-top: 20px;
    }
  }

  #vrtx-main-content {
    width: 100%;

    h1#hdr {
      margin-bottom: 50px;
    }
  }

  #vrtx-content,
  .container {
    width: 100%;
  }

  

  .vrtx-date-info {
    display: none;
  }

  #vrtx-additional-content {
    .grid-container {
      background: @color-brown;
    }

    .vrtx-frontpage-box a.feed-title,
    h2 {
      font-size: 28px;
      font-size: 2.8rem;
      line-height: 3.8rem;
      line-height: 38px;
      color: @color-dark;
      font-weight: bold;
    }

    .vrtx-frontpage-box {
      .vrtx-feed {
        width: 50%;
        float: left;
        margin-top: 0;
      }
    }
    /* Akruelt - arrangementer -publikasjoner - meny */
    #vrtx-breadcrumb-menu {
      display: none;
    }

    &.custom {
      width: 250px;
      padding: 20px;
      background: @color-brown;

      .grid-container {
        background: @color-brown;
      }
    }
  }
}
/* Sub site inside projects folder */
#main.project {
  .vrtx-breadcrumb-menu {
    .vrtx-ancestor {
      display: none;
    }
  }

  #right-main {
    #vrtx-additional-content,
    #vrtx-additional-content .grid-container {
      background: @color-brown;
    }

    #vrtx-additional-content {
      font-size: 0.8em;
      line-height: 24px;
      line-height: 2.4rem;
      padding: 20px;
      overflow: hidden;
      word-wrap: break-word;

      h2 {
        font-size: 0.7em;
      }
    }
  }
}

a.all-messages:before,
a.more-url:before,
a.vrtx-more:before,
div.vrtx-more a:before {
  background: url("../images/arrow-forward-blue.svg") no-repeat;
  background-size: 100% auto;
  display: inline-block;
  content: " ";
  width: 1.5em;
  height: 1.3em;
  position: relative;
  top: 0.3ex;
  margin-right: 10px;
}

.not-for-ansatte #bottomnav {
  display: none;
}

.not-for-ansatte #footer-wrapper {
  background: @color-neutral--dark;
  margin-top: 60px;

  #footers {
    background: url("../images/uiologo.png") no-repeat 0 15px;

    .col-3-5 {
      width: 70%;

      .col-1-3 {
        ul.links {
          margin-top: 0;

          li {
            //font-size: 1.8rem;
            margin-bottom: 15px;

            &::before {
              content: "";
            }
          }
        }
      }
    }

    .col-2-5 {
      width: 30%;

      &.responsible-login .content {
        margin-right: 0;
        margin-left: 60px;
      }
    }
  }

  * {
    color: @color-light;
  }

  a {
    color: @color-primary;

    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte #total-main div.top-info.person-listing h2,
  h1 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 39px;
    line-height: 3.9rem;
    text-align: left;
    display: block;
  }

  h2 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 34px;
    line-height: 3.4rem;
  }

  .not-for-ansatte #head-wrapper {
    background: @color-neutral--light;
  }
  /* general elements */
  #button a,
  .button a {
    display: inline-block;
    float: none;
    margin-bottom: 20px;
  }
  /* custom mobile menu */
  .js-ready #nav-offcanvas {
    width: 100%;
    height: 100%;
    background: @color-neutral--dark;

    div:first-child {
      li:first-of-type {
        width: 100%;
        height: 70px;
      }

      ul ul li:first-of-type {
        width: 100%;
        height: auto;
        background: transparent;
      }
    }

    li {
      background: transparent;
      border-top: none;
      text-align: center;

      a {
        padding: 1em 1.3em;
        font-size: 18px;
        font-size: 1.8rem;
        color: @color-primary;

        &#close-nav {
          float: right;
          width: auto;
        }
      }

      &.has-lang-link {
        margin-top: 20px;

        a {
          width: 100%;
          color: @color-primary;
        }
      }

      &.is-active {
        a {
          background: @color-neutral--dark;
          font-weight: 700;
          text-decoration: underline;
        }

        li a {
          text-decoration: none;
        }
      }

      a#close-nav,
      span.vrtx-no-url#close-nav {
        padding: 1.5em 3.8em 1.5em 1.5em;
        background: url("../images/close.svg") center right 20px no-repeat;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background-size: auto 2ex;
        background-size: auto 3.7ex;
        color: #aeaead;
        font-size: 14px;
        font-size: 1.4rem;
      }
    }

    #lang-link {
      position: relative;
      width: 100%;
      left: 0;
      text-align: center;
      background: transparent;
      border: none;

      a {
        padding-top: 1em;
        display: block;
        width: 100%;
        text-align: center;
        left: 0;
        margin-left: 0;
        color: @color-primary;

        // language icon
        &:before {
          content: "";
          display: inline-block;
          background: url("../images/english.svg") no-repeat;
          background-size: contain;
          height: 22px;
          width: 30px; // match negative margin left
          margin-left: -30px; // match width. subtract icon width to keep text centered.
          position: relative;
          top: 3px; // tweak y pos
        }
      }
    }
  }

  body.en #nav-offcanvas {
    #lang-link {
      a:before {
        background-image: url("../images/norwegian.png");
      }
    }
  }

  #nav-offcanvas li.ancestor,
  #nav-offcanvas li.is-active,
  #nav-offcanvas li.vrtx-parent ul > li,
  .js-ready #nav-offcanvas {
    background: @color-neutral--dark;
  }

  #nav-offcanvas {
    .nav-seperator {
      display: block;
      //height: 0.8em;
      height: auto;
      background: @color-neutral--dark;
    }

    li {
      &.is-active a {
        text-decoration: underline;
      }

      ul.children li {
        &:before {
          content: "";
        }

        a {
          font-size: 14px;
          display: block;
          outline: 0;
          color: @color-text-field;
          font-size: 14px;
          font-size: 1.4rem;
          color: @color-primary;
        }
      }

      &.vrtx-ancestor.ancestor {
        display: none;
      }

      &.ancestor {
        ul.children li.arkiv {
          display: none;
        }
      }
    }
  }

  .not-for-ansatte {
    #head-wrapper {
      #head {
        height: 120px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        #header {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
        }

        #header .logo {
          max-width: 75%;
          padding: 0;
          display: flex;
          position: relative;
          display: flex;
          align-items: center;

          a {
            padding: 0 0 0 15px;
          }

          a img {
            width: 265px;
            display: flex;
            vertical-align: middle;
          }
        }
      }
    }

    #menu-wrapper {
      display: block;
      position: absolute;
      right: 0;
      top: 23px;

      .menu {
        background: transparent;
        li {
          width: 100%;
          &:nth-of-type(2) {
            display: none;
          }
          &.active {
            background: @color-secondary--light;
          }
          a.toggle-navigation {
            padding: 15px 25px;
            .icon {
              background: url("../images/menu.svg") center left;
              height: 40px;
              width: 40px;
              display: inline-block;
              vertical-align: middle;
              background-size: 100%;
            }
            .text {
              display: none;
            }
          }
        }
      }
    }

    #main,
    .row {
      width: 100%;
    }

    #main #total-main #vrtx-content #vrtx-main-content {
      width: 100%;

      .full-width-picture-bg {
        margin-bottom: 0;
        margin-top: -15px;
      }

      .vrtx-frontpage-box.vrtx-frontpage-box-picture.sps-frontpage-box {
        .vrtx-box-content,
        .vrtx-frontpage-box-picture,
        h2 {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }

        .vrtx-frontpage-box-picture {
          position: relative;
          left: 0;

          img {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
    /* publications new */
    #main #total-main .grid-container .vrtx-frontpage-box.pub-top-bg {
      padding-left: 0;
      padding-right: 0;
    }
    /* Person listing */
    #main.vrtx-person #vrtx-listing-filter-hits {
      text-align: left;
      font-size: 16px;
    }

    #main.gen-person-component-listing #vrtx-main-content {
      .dig-person-listing .vrtx-box-content {
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }

    #total-main {
      div.top-info.person-listing {
        top: 0;

        .vrtx-introduction {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;

          p:first-of-type {
            font-size: 30px;
            font-size: 3rem;
            line-height: 39px;
            line-height: 3.9rem;
            display: block;
          }
        }

        h2,
        p {
          width: 100%;
          text-align: center;
        }
      }

      #vrtx-listing-filter-hits {
        text-align: left;
      }

      div.person-listing p {
        width: 100%;
        max-width: 100%;
        margin: 0;
        text-align: left;
      }

      #vrtx-listing-filter-results div.person,
      div.gen-person-component {
        width: 100%;
        float: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
    /* Publika */
    p.publika-headeryear {
      padding: 10px 15px 0;
    }

    p.publika-paper {
      padding: 0 15px;
    }
    /* Person page*/
    #main.vrtx-person-page {
      padding: 0;

      #total-main .vrtx-back {
        background: rgb(229, 223, 214);
        padding: 15px;
        margin-bottom: 0;

        a {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .header {
        margin-bottom: 40px;
        background: rgb(229, 223, 214);
        padding: 40px 15px;

        h1#hdr {
          text-align: center;
        }

        .person-image {
          margin-top: 30px;
        }
      }

      #total-main {
        .projects,
        a,
        h2,
        p:not(.publika-paper):not(.publika-headeryear),
        span:not(.publika-paper):not(.publika-author):not(.publika-authorselected):not(.publika-year):not(.publika-source):not(.publika-journal):not(.publika-volume):not(.publika-issue):not(.publika-pages):not(.publika-links):not(.publika-doi):not(.publika-pubmed):not(.publika-authors):not(.publika-title),
        ul {
          padding: 0 15px;
          display: block;
        }

        div.publika a {
          display: inline-block;
          padding: 0;
        }
      }

      #total-main h2:nth-of-type(1) {
        padding-top: 0;
      }

      .projects .project h3 {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    #main.vrtx-project .participants .participant {
      width: 100%;
      display: block;
      float: none;
      padding-bottom: 40px;
    }

    /* Project index page */
    #main.vrtx-project {
      #right-main {
        width: 100%;
      }

      #vrtx-additional-content {
        padding: 0;
        border-left: none;
        border-top: none;

        .vrtx-frontpage-box .vrtx-feed {
          width: 100%;
          float: none;
        }

        &.custom {
          padding-left: 0;
        }
      }
    }

    .top-banner.project {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    /* Project sub page */
    #main.project #right-main #vrtx-additional-content {
      padding: 15px;
      background: transparent;

      .grid-container {
        background: transparent;

        .vrtx-frontpage-box {
          padding: 0;
          border-top: 2px solid @color-line;

          &:nth-of-type(1) {
            padding-top: 40px;
          }
        }
      }
    }
    /* Publikasjoner page */
    .top-banner {
      max-height: none;
      margin-bottom: 15px;
    }

    .top-banner.pub {
      background-size: cover;
    }
    /* news feed news page */
    #main.vrtx-article-listing:not(.vrtx-project) .vrtx-resources {
      display: block;
      grid-template-columns: auto;
    }

    #main #total-main .vrtx-resources .vrtx-resource {
      width: 100%;
      display: block;
      float: none;
      margin-bottom: 0;
      padding-bottom: 40px;

      .vrtx-title {
        font-size: 22px;
        line-height: 1.3;
      }

      div.description.introduction {
        display: none;
      }

      a.vrtx-image {
        max-height: none;
        min-height: auto;
        overflow: hidden;
        margin-bottom: 5px;

        img {
          max-height: none;
          min-height: auto;
          width: 100%;
        }
      }

      .vrtx-title {
        min-height: auto;
      }
    }

    #footer-wrapper #footers {
      .col-2-5.responsible-login,
      .col-3-5.vcard {
        width: 100%;
      }

      .col-2-5.responsible-login .content {
        margin-left: 0;
      }

      ul.links li {
        margin-left: -14px;

        a {
          padding: 14px;
        }
      }
    }
  }
}
/*------------------------------------*\
# COLOR
\*------------------------------------*/
// font
@font-sans-serif: "Open Sans", sans-serif; // Primary, secondary etc...
@font-primary: @color-dark;
@color-primary: #00baee; //light-blue
@color-secondary: #0077b7; //denim
@color-secondary--light: #3ba9e2; //denim-light
@color-secondary--dark: #005684; //denim-dark
@color-link: @color-secondary;
@color-brown: #e5dfd6; // sand Neutral colors
@color-neutral--dark: #30272f; //black-ish
@color-neutral: #4f4f4f; //charcoal
@color-neutral--light: #f5f5f5;
@color-light: #ffffff;
@color-dark: #220720;
@color-text-field: #f3f3f3;
@color-line: #d8d8d8; //light grey

.mega-warning {
  font-size: 24px;
  color: @color-primary;
}
